<div class="reporting-elements-list-view-container">

    
    <!--header-->
    <div class="reporting-element-header-container">
        <!--reporting element info-->
        <div class="header-element-info" style="display:flex">
            <div style="max-width:350px;">
                <div style="margin-top:10px;">
                    <span class="head-label">Template Code:</span>
                    <span class="head-value">{{selectedTemplateCode}}</span>
                </div>
                <!-- <div>
                    <span class="head-label">Template Name:</span>
                    <span class="head-value">{{selectedTemplateName}}</span>
                </div> -->
            </div>
            <div>
                <div style="display: flex;margin-left:15px;">
                    <div style="margin-top:-10px;">
                        <div style="font-size:10px;font-family:Montserrat Medium;color:grey;">Document</div>
                            <div style="width:150px;font-family:Montserrat Medium;" *ngIf="tempDocList.length">
                                <ng-select id="docid"
                                    style="width:150px;font-size:10px;"
                                    [items]="tempDocList" 
                                    bindLabel="value"
                                    bindValue="value" 
                                    [clearable]="false"
                                    (change)="documentSelectionChange(selectedDocumentCategory)"
                                    [(ngModel)]="selectedDocumentCategory">
                                    <ng-template ng-option-tmp let-item="item">
                                        <div [title]="item.value">{{item.value}}</div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div style="margin-left:10px;margin-top:-10px;">
                            <div style="font-size:10px;font-family:Montserrat Medium;color:grey;">Template</div>
                            <div style="width:450px;font-family:Montserrat Medium;" *ngIf="tempList.length">
                                <ng-select id="docName"
                                    style="width:450px;font-size:10px;" 
                                    [items]="tempList" 
                                    bindLabel="labelTitle"
                                    bindValue="title" 
                                    [clearable]="false"
                                    [(ngModel)]="selectedDocument">
                                    <ng-template ng-option-tmp let-item="item">
                                        <div [title]="item.labelTitle"> {{item.labelTitle}}  </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div style="margin-top:7px;">
                            <button type="button" class="save-changes-btn" (click)="applyDocumentTypesFilter()"
                                style="width:40px;margin-left: 5px;">
                                Go
                            </button>
                        </div>

                        <!-- <div style="margin-top:7px;margin-left:20px;">
                            <button type="button" class="save-changes-btn">Save Changes</button>
                        </div> -->
                        <!-- <div style="margin-top:-10px;margin-left:20px;">
                                <div style="font-size:10px;font-family:Montserrat Medium;color:grey;">Set Relevance</div>
                                <div style="width:150px;font-family:Montserrat Medium;">
                                    <ng-select id="docid"
                                        style="width:150px;font-size:10px;"
                                        [items]="relevanceToCells" 
                                        bindLabel="label"
                                        bindValue="value" 
                                        [clearable]="false"
                                        (change)="setRelevanceForSelectedCells()"
                                        [(ngModel)]="relevanceOptionToset">
                                        <ng-template ng-option-tmp let-item="item">
                                            <div [title]="item.label">{{item.label}}</div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                        </div> -->
                    </div>

            </div>
            
            <!-- <span>
                <span class="head-label">Legal Reference:</span>
                <span class="head-value">2014/680</span>
            </span> -->
        </div>
        <!--reporting element info-->
        <!--header-right-section-->
        <div class="header-right-section">
            
            <!-- <div class=rag-status style="padding-top:8px;">
                <app-r-a-g-status></app-r-a-g-status>
            </div> -->
            <!-- <div class="column-customization-box">
                <span class="dropdown">
                    <span data-toggle="dropdown" style="cursor: pointer;">Customize Column</span>
                    <img data-toggle="dropdown" src="../../../assets/images/icon-dropdown.svg" />
                    <ul class="dropdown-menu dropdown-menu-right">
                        <li *ngFor="let column of columns; let i=index" class="dropdown-item" style="padding:0px 8px;">
                            <label (change)="onCheckboxChange($event, i)" style="font-size:12px;margin-bottom:2px;">
                                <input type="checkbox" [value]="column.title" [checked] = "!column.isHidden" style="vertical-align: middle;"/>
                                {{column.title}}
                            </label>
                        </li>
                    </ul>
                </span>
            </div> -->
        </div>
        <!--header-right-section-->
    </div>
    <!--header-->
    <div style="margin-top:6px;font-size:12px;">* To set relevance for the selected cell, do right click to open menu after cell selection.</div>
    
    <!--reporting elements content list-->
    <div class="reporting-element-list-container" style="margin-top:12px;">
        <!-- <div class="data-table">
            <table class="width100">
                <thead>
                    <tr>
                        <th>Row ID</th>
                        <th>Column ID</th>
                        <th>Row Name</th>
                        <th>Column Name</th>
                        <th style="min-width:125px;">
                            Relevance
                            <span>
                                <img src="../../../../assets/images/icon-dropdown.svg" />
                            </span>
                        </th>
                        <th style="min-width:125px;">
                            Compliance
                            <span>
                                <img src="../../../../assets/images/icon-dropdown.svg" />
                            </span>
                        </th>
                        <th>Instruction</th>
                        <th>Assessment</th>
                        <th>Assessment Owner</th>
                        <th>Action</th>
                        <th>Action Owner/Department</th>
                        <th>Action Status</th>
                        <th style="min-width:95px;">Action Due Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of reportingElementsListData; let i = index;">
                        <td>
                            <span class="number-value">{{data.rowId}}</span>
                        </td>
                        <td>
                            <span class="number-value">{{data.colId}}</span>
                        </td>
                        <td>
                            <span class="text-value">{{data.rowName}}</span>
                        </td>
                        <td>
                            <span class="text-value">{{data.colName}}</span>
                        </td>
                        <td>
                            <span class="text-value">
                                <ng-select id="relevance{{i}}" 
                                    [items]="relevanceOptions" 
                                    bindLabel="label"
                                    bindValue="value" 
                                    [(ngModel)]="data.relevance"
                                    (change)="relevanceToReportingElementChanged(data)">
                                    <ng-template ng-label-tmp let-item="item">
                                        <div style="display:flex;">
                                            <div style="width:4px;border-radius:4px;margin-right:4px;" [style.background]="item.barColor"></div>
                                            <div>{{item.label}}</div>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </span>
                        </td>
                        <td>
                            <span class="text-value">
                                <ng-select id="compliance{{i}}" 
                                    [items]="complianceOptions" 
                                    bindLabel="label"
                                    bindValue="value" 
                                    [(ngModel)]="data.compliance"
                                    (change)="relevanceToReportingElementChanged(data)">
                                    <ng-template ng-label-tmp let-item="item">
                                        <div style="display:flex;">
                                            <div style="width:4px;border-radius:4px;margin-right:4px;" [style.background]="item.barColor"></div>
                                            <div>{{item.label}}</div>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </span>
                        </td>
                        <td>
                            <span class="text-value">{{data.instruction}}</span>
                        </td>
                        <td>
                            <span class="text-value">{{data.assessment}}</span>
                        </td>
                        <td>
                            <span class="text-value">
                                <ng-select id="compliance{{i}}" 
                                    [items]="assessmentOwnersList" 
                                    bindLabel="label"
                                    bindValue="value" 
                                    [(ngModel)]="data.assessment_owner"
                                    (change)="relevanceToReportingElementChanged(data)">
                                </ng-select>
                            </span>
                        </td>
                        <td>
                            <span class="text-value">{{data.action}}</span>
                        </td>
                        <td>
                            <span class="text-value">{{data.actionOwner}}</span>
                        </td>
                        <td>
                            <span class="text-value">{{data.actionStatus}}</span>
                        </td>
                        <td>
                            <span class="text-value">{{data.actionDueDate}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div> -->
        <div  class="reporting-element-list-tab-container" id="reporting-element-list-tab-container" >
            <div class="hot" style="font-family: 'Montserrat Medium';font-size: 12px;" id="reporting-element-list-tab-table">
            </div>
        </div>

        
    </div>
    <!--reporting elements content list-->
    <!--fril image template 7 hardcoded table-->
        
            
    <table *ngIf="selectedTemplateCode == 'Template 7' && selectedDocument == 'Mitigating actions: Assets for the calculation of GAR'" class="hardcoded-table" style="border-collapse: collapse;border-spacing: 0">
        <tr style="border-bottom: 1px solid black;text-align: center;">
            <td colspan="2"></td>
            <td style="width:80px;border-top: 1px solid black;border-left: 1px solid black;">a</td>
            <td style="width:80px;border-top: 1px solid black;border-left: 1px solid black;">b</td>
            <td style="width:80px;border-top: 1px solid black;border-left: 1px solid black;">c</td>
            <td style="width:80px;border-top: 1px solid black;border-left: 1px solid black;">d</td>
            <td style="width:80px;border-top: 1px solid black;border-left: 1px solid black;">e</td>
            <td style="width:80px;border-top: 1px solid black;border-left: 1px solid black;">f</td>
            <td style="width:80px;border-top: 1px solid black;border-left: 1px solid black;">g</td>
            <td style="width:80px;border-top: 1px solid black;border-left: 1px solid black;">h</td>
            <td style="width:80px;border-top: 1px solid black;border-left: 1px solid black;">i</td>
            <td style="width:80px;border-top: 1px solid black;border-left: 1px solid black;">j</td>
            <td style="width:80px;border-top: 1px solid black;border-left: 1px solid black;">k</td>
            <td style="width:80px;border-top: 1px solid black;border-left: 1px solid black;">l</td>
            <td style="width:80px;border-top: 1px solid black;border-left: 1px solid black;">m</td>
            <td style="width:80px;border-top: 1px solid black;border-left: 1px solid black;">n</td>
            <td style="width:80px;border-top: 1px solid black;border-left: 1px solid black;">o</td>
            <td style="width:80px;border-top: 1px solid black;border-left: 1px solid black;border-right:1px solid black;">p</td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td colspan="2" style="border-right: 1px solid black;text-align: center;">Million EUR</td>
            <td>Total gross carrying amount</td>
            <td colspan="15">
                <table style="border-collapse: collapse;border-spacing: 0">
                    <tr>
                        <td colspan="3" style="text-align: center;">Disclosure reference date T</td>
                    </tr>
                    <tr style="border-top: 1px solid black;border-left: 1px solid black;border-bottom: 1px solid black;text-align: center;">
                        <td style="width:400px;font-weight: bold;background-color: lightgray;border-right: 1px solid black;">
                            Climate Change Mitigation (CCM)
                        </td>
                        <td style="width:400px;font-weight: bold;background-color: lightgray;border-right: 1px solid black;">
                            Climate Change Adaptation (CCA)
                        </td>
                        <td style="width:400px;font-weight: bold;background-color: lightgray;">TOTAL (CCM + CCA)</td>
                    </tr>
                    <tr>
                        <td  style="border-left: 1px solid black;position: relative;">
                            <table style="border-collapse: collapse;border-spacing: 0">
                                <tr>
                                    <td colspan="2">Of which towards taxonomy relevant sectors (Taxonomy-eligible)</td>
                                </tr>
                                <tr>
                                    <td style="width: 80px;"></td>
                                    <td  style="border-left: 1px solid black;position: relative;">
                                        <table style="border-collapse: collapse;border-spacing: 0">
                                            <tr>
                                                <td colspan="4" style="border-top: 1px solid black;">    
                                                    of which environmentally sustainable (Taxonomy-aligned)
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 80px;"></td>
                                                <td style="width: 80px;border-left: 1px solid black;border-top: 1px solid black;">
                                                    of which specialised lending
                                                </td>
                                                <td style="width: 80px;border-left: 1px solid black;border-top: 1px solid black;">
                                                    of which transitional
                                                </td>
                                                <td style="width: 80px;border-left: 1px solid black;border-top: 1px solid black;">
                                                    of which enabling
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td  style="border-left: 1px solid black;position: relative;">
                            <table style="border-collapse: collapse;border-spacing: 0">
                                <tr>
                                    <td colspan="2">Of which towards taxonomy relevant sectors (Taxonomy-eligible)</td>
                                </tr>
                                <tr>
                                    <td style="width: 80px;"></td>
                                    <td  style="border-left: 1px solid black;position: relative;">
                                        <table style="border-collapse: collapse;border-spacing: 0">
                                            <tr>
                                                <td colspan="4" style="border-top: 1px solid black;">    
                                                    of which environmentally sustainable (Taxonomy-aligned)
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 80px;"></td>
                                                <td style="width: 80px;border-left: 1px solid black;border-top: 1px solid black;">
                                                    of which specialised lending
                                                </td>
                                                <td style="width: 80px;border-left: 1px solid black;border-top: 1px solid black;">
                                                    of which adaptation
                                                </td>
                                                <td style="width: 80px;border-left: 1px solid black;border-top: 1px solid black;">
                                                    of which enabling
                                                </td>
                                            </tr>
                                        </table>
                                    
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td  style="border-left: 1px solid black;position: relative;">
                            <table style="border-collapse: collapse;border-spacing: 0">
                                <tr>
                                    <td colspan="2">Of which towards taxonomy relevant sectors (Taxonomy-eligible)</td>
                                </tr>
                                <tr>
                                    <td style="width: 80px;"></td>
                                    <td  style="border-left: 1px solid black;position: relative;">
                                        <table  style="border-collapse: collapse;border-spacing: 0">
                                            <tr>
                                                <td colspan="4" style="border-top: 1px solid black;">    
                                                    of which environmentally sustainable (Taxonomy-aligned)
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width:80px;"></td>
                                                <td style="width: 80px;border-left: 1px solid black;border-top: 1px solid black;">
                                                    of which specialised lending
                                                </td>
                                                <td style="width: 80px;border-left: 1px solid black;border-top: 1px solid black;">
                                                    of which transitional/ adaptation
                                                </td>
                                                <td style="width: 80px;border-left: 1px solid black;border-top: 1px solid black;">
                                                    of which enabling
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
                
            
            </td>
            
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td colspan="18" style="font-weight: bold;background-color: lightgrey;padding-left: 50px;">
                GAR - Covered assets in both numerator and denominator
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="width: 50px;text-align: center;">1</td>
            <td style="border-left: 1px solid black;padding-left:2px;">Loans and advances, debt securities and equity instruments not HFT eligible for GAR calculation</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">2</td>
            <td style="font-weight: bold;border-left: 1px solid black;padding-left:6px;">Financial corporations</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">3</td>
            <td  style="border-left: 1px solid black;padding-left:8px;">Credit institutions</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">4</td>
            <td style="border-left: 1px solid black;padding-left:10px;">Loans and advances</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">5</td>
            <td style="border-left: 1px solid black;padding-left:10px;">Debt securities, including UoP</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">6</td>
            <td style="border-left: 1px solid black;padding-left:10px;">Equity instruments</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style="background-color: lightgray;border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">7</td>
            <td style="border-left: 1px solid black;padding-left:8px;">Other financial corporations</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">8</td>
            <td style="border-left: 1px solid black;padding-left:10px;">of which investment firms</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">9</td>
            <td style="border-left: 1px solid black;padding-left:14px;">Loans and advances</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">10</td>
            <td style="border-left: 1px solid black;padding-left:10px;">Debt securities, including UoP</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">11</td>
            <td style="border-left: 1px solid black;padding-left:14px;">Equity instruments</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">12</td>
            <td style="border-left: 1px solid black;padding-left:10px;">of which management companies</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td class="context-cell hardcodedd12cell" style="border-left: 1px solid black;position: relative;background-color: #5aac44;">
                <img (click)="goToReportingElementDetailsHardcode('d12')" style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">13</td>
            <td style="border-left: 1px solid black;padding-left:14px;">Loans and advances</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">14</td>
            <td style="border-left: 1px solid black;padding-left:10px;">Debt securities, including UoP</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">15</td>
            <td style="border-left: 1px solid black;padding-left:14px;">Equity instruments</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">16</td>
            <td style="border-left: 1px solid black;padding-left:10px;">of which insurance undertakings</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img (click)="goToReportingElementDetailsHardcode('d16')" style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">17</td>
            <td style="border-left: 1px solid black;padding-left:14px;">Loans and advances</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">18</td>
            <td style="border-left: 1px solid black;padding-left:10px;">Debt securities, including UoP</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">19</td>
            <td style="border-left: 1px solid black;padding-left:14px;">Equity instruments</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">20</td>
            <td style=" font-weight: bold;border-left: 1px solid black;padding-left:6px;">Non-financial corporations (subject to NFRD disclosure obligations)</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">21</td>
            <td style="border-left: 1px solid black;padding-left:10px;">Loans and advances</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">22</td>
            <td style="border-left: 1px solid black;padding-left:10px;">Debt securities, including UoP</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">23</td>
            <td style="border-left: 1px solid black;padding-left:10px;">Equity instruments</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">24</td>
            <td style=" font-weight: bold;border-left: 1px solid black;padding-left:6px;">Households</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">25</td>
            <td style="border-left: 1px solid black;padding-left:10px;">of which loans collateralised by residential immovable property</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">26</td>
            <td style="border-left: 1px solid black;padding-left:10px;">of which building renovation loans</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">27</td>
            <td style="border-left: 1px solid black;padding-left:10px;">of which motor vehicle loans</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgray;border-left: 1px solid black;position:relative">
   <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">28</td>
            <td style=" font-weight: bold;border-left: 1px solid black;padding-left:6px;">Local governments financing</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">29</td>
            <td style="border-left: 1px solid black;padding-left:10px;">Housing financing</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">30</td>
            <td style="border-left: 1px solid black;padding-left:10px;">Other local governments financing</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">31</td>
            <td style="border-left: 1px solid black;padding-left:6px;">Collateral obtained by taking possession: residential and commercial immovable properties</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">32</td>
            <td style=" font-weight: bold;border-left: 1px solid black;">TOTAL GAR ASSETS</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td colspan="18" style=" font-weight: bold;background-color: lightgray;padding-left: 50px;">
            Assets excluded from the numerator for GAR calculation (covered in the denominator)
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">33</td>
            <td style=" font-weight: bold;border-left: 1px solid black;padding-left:6px;">
                EU Non-financial corporations (not subject to NFRD disclosure obligations)
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">34</td>
            <td style="border-left: 1px solid black;padding-left:10px;">Loans and advances</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">35</td>
            <td style="border-left: 1px solid black;padding-left:10px;">Debt securities</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">36</td>
            <td style="border-left: 1px solid black;padding-left:10px;">Equity instruments</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">37</td>
            <td style="font-weight: bold;border-left: 1px solid black;padding-left:6px;">
            Non-EU Non-financial corporations (not subject to NFRD disclosure obligations)
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">38</td>
            <td style="border-left: 1px solid black;padding-left:10px;">Loans and advances</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
    <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
</td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">39</td>
            <td style="border-left: 1px solid black;padding-left:10px;">Debt securities</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">40</td>
            <td style="border-left: 1px solid black;padding-left:10px;">Equity instruments</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">41</td>
            <td style="border-left: 1px solid black;padding-left:2px;">Derivatives</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">42</td>
            <td style="border-left: 1px solid black;padding-left:2px;">On demand interbank loans</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">43</td>
            <td style="border-left: 1px solid black;padding-left:2px;">Cash and cash-related assets</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">44</td>
            <td style="border-left: 1px solid black;padding-left:2px;">Other assets (e.g. Goodwill, commodities etc.)</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">45</td>
            <td style="font-weight: bold;border-left: 1px solid black;">
                TOTAL ASSETS IN THE DENOMINATOR (GAR)
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td colspan="18" style=" font-weight: bold;background-color: lightgray;padding-left: 50px;">
            Other assets excluded from both the numerator and denominator for GAR calculation
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">46</td>
            <td style="border-left: 1px solid black;padding-left:2px;">Sovereigns</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">47</td>
            <td style="border-left: 1px solid black;padding-left:2px;">Central banks exposure</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">48</td>
            <td style="border-left: 1px solid black;padding-left:2px;">Trading book</td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">49</td>
            <td style=" font-weight: bold;border-left: 1px solid black;">
                TOTAL ASSETS EXCLUDED FROM NUMERATOR AND DENOMINATOR
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
        <tr style="border-left: 1px solid black;border-right: 1px solid black;border-bottom: 1px solid black;">
            <td style="text-align: center;">50</td>
            <td style=" font-weight: bold;border-left: 1px solid black;">
                TOTAL ASSETS
            </td>
            <td  style="border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style=" background-color: lightgrey;border-left: 1px solid black;position:relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
            <td style="background-color: lightgrey;border-left: 1px solid black;position: relative;">
                <img style="width: 12px;cursor: pointer;position: absolute;right: 5px;top: 5px;"  src="../../../assets/images/bars-solid.svg" />
            </td>
        </tr>
    </table>
    <!--fril image template 7 hardcoded table-->
</div>
<!--loader-->
<div style="position: absolute;top:40%;left:40%;" *ngIf="showLoader">
    <img src="../../../assets/images/loader1.gif"/>
</div>


<!-- Context Menu -->
<div id="context-menu" class="context-menu">
    <ul>
        <li (click)="hardcodedTableRelevanced12('Y')">Relevance: Yes</li>
        <li (click)="hardcodedTableRelevanced12('N')">Relevance: No</li>
        <li (click)="hardcodedTableRelevanced12('TBD')">Relevance: TBD</li>
    </ul>
</div>
